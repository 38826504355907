import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        'li': {
            display: 'block;'
        },
        'li: before': {
            content: 'counters(item, ".") ". "',
            counterIncrement: 'item'
        },
        'ol': {
            counterReset: 'item'
        },
        'li strong': {
            display: 'inline-block',
            margin: '10px 0'
        }
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    },
    mb: {
       marginBottom: '40px'
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content} >
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Customer Privacy Notice
                </Typography>
                    <p>
                    This Notice explains how we obtain, use and disclose your personal information, as is required by the Protection of Personal Information Act (“PPI”).
                    </p>
                    <p>
                    Hyundai Automotive South Africa (Proprietary) Limited ("Hyundai"), including its holding company, subsidiaries and associate companies, recognizes that privacy is important.
                    </p>
                    <p>
                    We are committed to maintaining the privacy and security of the users of our website. This Privacy Notice applies to all our services, products and our website, and outlines Hyundai’s practices and commitment to the users of our website in this regard.
                    </p>
                    <p className={classes.mb}>
                    This Privacy Notice must be read with the terms and conditions of the User Agreement that is incorporated into this Privacy Notice.
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    The Information We Collect
                    </Typography>
                    <p>
                    We collect and process your personal information mainly to provide you with access to our services and products, to help us improve our offerings to you and for certain other purposes explained below.
                    </p>
                    <p>
                    The type of information we collect will depend on the purpose for which it is collected and used. We will only collect information that we need for that purpose.
                    </p>
                    <p>
                    We collect information directly from you where you provide us with your personal details, for example when you purchase a product or services from us or when you submit enquiries to us or contact us. Where possible, we will inform you what information you are required to provide to us and what information is optional.
                    </p>
                    <p>
                    We also collect information about you from other sources as explained below.
                    </p>
                    <p>
                    With your consent, we may also supplement the information that you provide to us with information we receive from other companies which form part of the Hyundai Group in order to offer you a more consistent and personalized experience in your interactions with Hyundai.
                    </p>
                    <p className={classes.mb}>
                    Website usage information is collected using “cookies” which allows us to collect standard internet visitor usage information.
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    How We Use Your Information
                    </Typography>
                    <p>
                    We will use your personal information only for the purposes for which it was collected or agreed with you, for example:
                    </p>
                    <ul className={classes.mb}>
                        <li>To provide our products or services to you, to carry out the transaction you requested and to maintain our relationship;</li>
                        <li>To help improve the quality of our products and services;</li>
                        <li>To help recover debts;</li>
                        <li>For general administration purposes;</li>
                        <li>To confirm and verify your identity or to verify that you are an authorised user for security purposes;</li>
                        <li>For the detection and prevention of fraud, crime, money laundering or other malpractice;</li>
                        <li>To conduct market or customer satisfaction research or for statistical analysis;</li>
                        <li>To identify other products and services which might be of interest to you and inform you about our products and services; and <br/>
                            In connection with legal proceedings.</li>
                        <li>We will also use your personal information to comply with legal and regulatory requirements or industry codes to which we subscribe or which apply to us, or when it is otherwise allowed by law (for example to protect Hyundai's interests).</li>
                    </ul>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Disclosure of Information
                    </Typography>
                    <p>
                    We may disclose your personal information to our service providers who are involved in the delivery of products or services to you. We have agreements in place to ensure that they comply with these privacy terms.
                    </p>
                    <p className={classes.mb}>
                    We may share your personal information with, and obtain information about you from:
                    Third parties for the purposes listed above, for example credit reference and fraud prevention agencies, law enforcement agencies; Other insurers to prevent fraudulent claims;
                    Other companies in the Hyundai Group when we believe it will enhance the services and products we can offer to you, but only where you have not objected to such sharing;
                    Other third parties from whom you have chosen to receive marketing information.
                    We may also disclose your information:
                    Where we have a duty or a right to disclose in terms of law or industry codes;
                    Where we believe it is necessary to protect our rights.
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Information Security
                    </Typography>
                    <p>
                    We are legally obliged to provide adequate protection for the personal information we hold and to stop unauthorised access and use of personal information. We will, on an ongoing basis, continue to review our security controls and related processes to ensure that your personal information is secure.
                    </p>
                    <p>
                    Our security policies and procedures cover:
                    </p>
                    <ul className={classes.mb}>
                        <li>Physical security;</li>
                        <li>Computer and network security;</li>
                        <li>Access to personal information;</li>
                        <li>Secure communications;</li>
                        <li>Security in contracting out activities or functions;</li>
                        <li>Retention and disposal of information;</li>
                        <li>Acceptable usage of personal information;</li>
                        <li>Governance and regulatory issues;</li>
                        <li>Monitoring access and usage of private information;</li>
                        <li>Investigating and reacting to security incidents.</li>
                        <li>When we contract with third parties, we impose appropriate security, privacy and confidentiality obligations on them to ensure that personal information that we remain responsible for, is kept secure.</li>
                        <li>We may need to transfer your personal information to another country for processing or storage. We will ensure that anyone to whom we pass your personal information agrees to treat your information with the same level of protection as we are obliged to.</li>
                    </ul>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Your Rights: Access to Information
                    </Typography>
                    <p>
                    You have the right to request a copy of the personal information we hold about you. To do this, simply contact us at the address listed below and specify what information you would like. We will take all reasonable steps to confirm your identity before providing details of your personal information.
                    </p>
                    <p className={classes.mb}>
                    Please note that any such access request may be subject to a payment of a legally allowable fee.
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Correction of Your Information
                    </Typography>
                    <p>
                    In certain cases, you have the right to object to the processing of your personal information. You have the right to ask us to update, correct or delete your personal information.
                    </p>
                    <p>
                    We will take all reasonable steps to confirm your identity before making changes to personal information we may hold about you.
                    </p>
                    <p className={classes.mb}>
                    We would appreciate it if you would keep your personal information accurate.
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Changes to this Notice
                    </Typography>
                    <p className={classes.mb}>
                    Please note that we may amend this Notice from time to time. Please check this website periodically to inform yourself of any changes.
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    How to Contact Us
                    </Typography>
                    <p>
                    If you have questions about this Notice or believe we have not adhered to it, or need further information about our privacy practices or wish to give or withdraw consent, exercise preferences or access or correct your personal information, please contact us at the following numbers/addresses:
                    </p>
                    <p className={classes.mb}>
                    webmaster@hyundai.co.za
                    </p>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Information Regulator
                    </Typography>
                    <p>
                    You have the right to complain to the Information Regulator, whose contact details are:
                    </p>
                    <p>
                    Tel:012 406 4818
                <br/>
                    Fax: 086 500 3351
                    <br/>
                    Email: inforeg@justice.gov.za
                    </p>
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    Tracking Analytics
                </Typography>
                <p>
                    We use <Link component={RouterLink} to="/cookies" className={classes.link}>"cookies"</Link> on this webiste.
                </p>

                <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
    )
}

export default Terms
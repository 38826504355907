import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { ImageContainer } from "../shared/ImageContainer";
import ContactComponent from "../shared/ContactComponent";
import DealerDetailsController from "./DealerDetailsController";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#e4dcd3",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  link: {
    backgroundColor: "#002b5e",
    marginBottom: "1rem",
    color: "#ffffff",
    fontWeight: "700",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const { loading, dealer } = DealerDetailsController();
  let splitAddress = dealer?.address?.streetAddress.split(",");
  return (
    <Container maxWidth="lg">
      <Box>
        {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
      </Box>
      <div className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          Our Customer Relations team are here to help you
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Box mb={3} mt={3}>
          <Box textAlign="center" fontSize={"1.5rem"}>
            Send us your details and we will call you
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {!loading && (
              <div>
                <Box fontWeight="fontWeightBold">Sales</Box>
                <Box mb={3}>{dealer?.newCarsPhoneNumber}</Box>
                <Box fontWeight="fontWeightBold">Address</Box>
                <Box>{splitAddress[0]?.trim()}</Box>
                <Box>{splitAddress[1]?.trim()}</Box>
                <Box mb={3}>{dealer?.address?.streetAddressPostal}</Box>
                <Box fontWeight="fontWeightBold">Email</Box>
                <Box mb={3}>
                  <a
                    className={classes.url}
                    href={`mailto:${dealer?.email}`}
                    title="Drop us message on email"
                  >
                    {dealer?.email}
                  </a>
                </Box>
              </div>
            )}
            <Box fontWeight="fontWeightBold">Opening Hours</Box>
            <Box>Monday - Friday 08:00 - 17:30</Box>
            <Box>Saturday: 09:00 - 13:00</Box>
            <Box mb={3}>Sunday: Closed</Box>

            <Box fontWeight="fontWeightBold">Live Chat Hours</Box>
            <Box>Monday - Sunday : Open</Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <ContactComponent leadTypeId={3} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default ContactUs;

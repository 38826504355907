import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    promotionContainer: {
        position: 'absolute',
        height: '100%',
        backgroundColor: '#e4dcd3',
        padding: '0 1rem',
        '@media (max-width: 624px)': {
            position: 'relative',
            height: 'auto'
        },
        "&:after": {
            content: '" "',
            position: 'absolute',
            display: 'block',
            right: '-25px',
            top: 0,
            bottom: 0,
            width: '25px',
            height: '100%',
            background: '#e4dcd3',
            clipPath: 'polygon(100% 0%, 100% 100%, 0% 50%)',
            transform: 'rotate(180deg)'
        }
    },
    content: {
        padding: '2rem',
        backgroundColor: '#00000099',
        minWidth: '250px',
        ' & > div': {
            color: '#ffffff'
        }
    },
    vehicleName: {
        '& > h4': {
            fontWeight: 400
        }
    },
    vehiclePrice: {
        '& > h4': {
            fontWeight: 400
        }
    },
    callToAction: {
        width: '100%'
    }
}));

const SlidePromotion = ({ vehicleName, tagline, price, callToAction, url }) => {
    const classes = useStyles();

    return (
        <Box className={classes.promotionContainer}>
            <Box className={classes.content}>
                <Box className={classes.vehicleName} variant="h4"></Box>
                <Box mb={2}><b dangerouslySetInnerHTML={{ __html: tagline }}></b></Box>
                
                <Box className={classes.vehiclePrice}><Typography variant="h4">{price}</Typography></Box>
                <Box mt={1}>
                    <Button variant="contained" color="primary" href={url} className={classes.callToAction}>
                        {callToAction}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default SlidePromotion;
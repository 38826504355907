import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import Carousel from "./Carousel";
import carouselImage from "./../shared/assets/home-slide-1.jpg";
import { BASE_URI, PAGE_SIZE, DEALERID } from "../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import { useHistory } from "react-router-dom";
import SlidePromotion from "./SlidePromotion";
import { useMediaQuery } from "react-responsive";
import OurVehicles from "../ourVehiclesPage/OurVehicles";
import { FinanceContext } from "../shared/contexts/FinanceContext";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  introContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
    "@media (max-width: 624px)": {
      paddingTop: 0,
    },
    "@media (min-width: 555px) and (max-width: 624px)": {
      marginTop: "-130px",
    },
    "@media (min-width: 512px) and (max-width: 554px)": {
      marginTop: "-100px",
    },
    "@media (min-width: 480px) and (max-width: 511px)": {
      marginTop: "-80px",
    },
    "@media (min-width: 430px) and (max-width: 479px)": {
      marginTop: "-45px",
    },
    "@media (min-width: 400px) and (max-width: 429px)": {
      marginTop: "30px",
    },
    "@media (min-width: 360px) and (max-width: 399px)": {
      marginTop: "-5px",
    },
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  threeSteps: {
    backgroundColor: "#e4dcd3",
    color: "#212529f",
  },
  redbtn: {
    backgroundColor: "#002b5e",
    color: "#ffffff",
    "&:hover": {
      color: "#000000",
    },
  },
  featureDeal: {
    borderBottom: "1px solid #cccccc",
    paddingTop: "20px",
    paddingBottom: "20px",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  xlbutton: {
    padding: "13px 36px",
    fontSize: "1.1rem",
  },
  noPadding: {
    paddingBottom: 0,
  },
  featureDealImage: {
    "@media (max-width: 599px)": {
      height: "auto",
    },
  },
  centeredContent: {
    "@media (max-width: 1279px)": {
      justifyContent: "center",
    },
  },
}));

export default function HomeComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [deals, setDeals] = useState([]);
  const mountedRef = useRef(true);
  const history = useHistory();
  const [slides, setSlides] = useState([]);
  let { globalVehicles } = useContext(FinanceContext);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    setLoading(true);
    const source = CancelToken.source();
    const getOffer = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: DEALERID,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/Offers`,
          params,
          cancelToken: source.token,
        });

        setLoading(false);
        let data = result.data;
        setDeals(data.list);
      } catch (error) {
        setLoading(false);
      }
    };

    getOffer().then(() => {
      setLoading(false);
      if (!mountedRef.current) return null;
    });

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: DEALERID,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        setLoading(false);

        var slides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            content: (
              <SlidePromotion
                vehicleName={itm.make}
                tagline={itm.cta}
                price={""}
                url={itm.url}
                callToAction={itm.buttonText}
              />
            ),
          };
        });

        setSlides(slides);
      } catch (error) {
        setLoading(false);
      }
    };

    getSlides();

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 624px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 912px)" });

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  return (
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <Container maxWidth="lg">
          <Carousel
            naturalSlideWidth={100}
            naturalSlideHeight={isMobile ? 105 : isTabletOrMobile ? 38 : 32}
            totalSlides={slides.length}
            isPlaying={true}
            infinite
            slides={slides}
          />
          <div className={classes.introContent}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Welcome to Hyundai Automotive, the future of the car buying
              experience.
            </Typography>
            <Typography
              align="center"
              paragraph
              gutterBottom
              style={{ fontSize: "1.4rem" }}
            >
              Our website is designed to assist you in obtaining finance without
              the need to leave your home or office. Our dedicated team are here
              to assist you every step of the way and we are available on Live
              chat in the bottom right corner of this page. Once your
              application is finalized we will deliver your car to your home or
              office.
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
              <Box fontWeight="fontWeightBold">
                We have a national network of dealers to assist you and we
                accept trade-in’s and if you require a test drive, we come to
                you!
              </Box>
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    className={classes.xlbutton}
                    size="large"
                    variant="contained"
                    color="primary"
                    href="/apply-for-finance"
                  >
                    Apply Now
                  </Button>
                </Grid>
                <Grid item style={{ display: "none" }}>
                  <Button
                    className={classes.xlbutton}
                    variant="contained"
                    color="primary"
                    style={{ display: "none" }}
                  >
                    Book a test drive
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.xlbutton}
                    variant="contained"
                    color="primary"
                    href="/special-deals"
                  >
                    Browse our deals
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
        <div className={classes.threeSteps}>
          <Container maxWidth="lg" className={classes.cardGrid}>
            <Typography
              gutterBottom
              variant="h4"
              align="center"
              fontWeight="500"
            >
              <Box
                fontWeight={500}
                style={{ textTransform: "uppercase", fontWeight: 700 }}
              >
                BUY YOUR CAR IN 3 EASY STEPS
              </Box>
            </Typography>
            <Grid container spacing={4} style={{ marginTop: "1rem" }}>
              <Grid item xs={12} sm={12} md={4} align="center">
                <Box mb={2}>
                  <SettingsBackupRestore style={{ fontSize: 60 }} />
                </Box>
                <Typography gutterBottom variant="h6" align="center">
                  <Box fontWeight="fontWeightBold">PRE-APPROVAL</Box>
                </Typography>
                <Typography align="center">
                  Answer a few questions and we will tell you what you qualify
                  for.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} align="center">
                <Box mb={2}>
                  <CreditCardIcon style={{ fontSize: 60 }} />
                </Box>
                <Typography gutterBottom variant="h6" align="center">
                  <Box fontWeight="fontWeightBold">APPLY</Box>
                </Typography>
                <Typography align="center">
                  Apply online and receive a real time answer from SA's leading
                  banks.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} align="center">
                <Box mb={2}>
                  <DirectionsCarIcon style={{ fontSize: 60 }} />
                </Box>
                <Typography gutterBottom variant="h6" align="center">
                  <Box fontWeight="fontWeightBold">DRIVE</Box>
                </Typography>
                <Typography align="center">
                  We will deliver your vehicle to your home or office in 24
                  hours.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  className={`${classes.redbtn} ${classes.xlbutton}`}
                  href="/apply-for-finance"
                >
                  start now
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container
          className={`${classes.cardGrid} ${classes.noPadding}`}
          maxWidth="lg"
        >
          <Typography
            gutterBottom
            variant="h4"
            align="center"
            color="textPrimary"
          >
            <Box
              fontWeight={500}
              mb={5}
              style={{ textTransform: "uppercase", fontWeight: 700 }}
            >
              Featured Offers
            </Box>
          </Typography>
          {loading && (
            <Grid container spacing={4} justify="center" alignContent="center">
              <Grid item>
                <CircularProgress size={80} />
              </Grid>
            </Grid>
          )}

          {!loading && deals.length === 0 && (
            <Grid container spacing={4} justify="center">
              <Grid item xs={12}>
                <Typography color="textSecondary">
                  No available deals
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} className={classes.centeredContent}>
            {!loading &&
              deals.length > 0 &&
              deals.map((deal) => (
                <Grid item xs={12} sm={12} md={8} lg={6} key={deal.id}>
                  <Box className={classes.featureDeal}>
                    <Grid container spacing={4} justify="center">
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          height={310}
                          width={"100%"}
                          className={classes.featureDealImage}
                        >
                          <ImageContainer
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                            src={deal.imageUrl}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="h5" color="textPrimary">
                          <Box fontWeight={500}>{deal.headline}</Box>
                        </Typography>
                        <Typography>
                          From <CurrencyValue value={deal.price} /> or{" "}
                          <CurrencyValue value={deal.monthlyInstallment} />
                        </Typography>
                        <Box display="flex" mt={3} pb={1}>
                          <Box width={200}>
                            <Typography>
                              <strong>Purchase Price</strong>
                            </Typography>
                          </Box>
                          <Typography>
                            <CurrencyValue value={deal.price} />
                          </Typography>
                        </Box>
                        <Box display="flex" pb={1}>
                          <Box width={200}>
                            <Typography>
                              <strong>Monthly Installment</strong>
                            </Typography>
                          </Box>
                          <Typography>
                            <CurrencyValue value={deal.monthlyInstallment} />
                            pm
                          </Typography>
                        </Box>
                        <Box display="flex" pb={1}>
                          <Box width={200}>
                            <Typography>
                              <strong>Term (Months)</strong>
                            </Typography>
                          </Box>
                          <Typography>{deal.term}</Typography>
                        </Box>
                        <Box display="flex" pb={1}>
                          <Box width={200}>
                            <Typography>
                              <strong>Linked Interest Rate</strong>
                            </Typography>
                          </Box>
                          <Typography>{deal.rate}%</Typography>
                        </Box>
                        <Box display="flex" pb={1}>
                          <Box width={200}>
                            <Typography>
                              <strong>Deposit</strong>
                            </Typography>
                          </Box>
                          <Typography>{deal.deposit}%</Typography>
                        </Box>
                        <Box display="flex" pb={1}>
                          <Box width={200}>
                            <Typography>
                              <strong>Balloon Payment</strong>
                            </Typography>
                          </Box>
                          <Typography>{deal.balloonPayment}%</Typography>
                        </Box>
                        <Button
                          variant="contained"
                          onClick={() =>
                            history.push({
                              pathname: `models/${cleanModel(deal.headline)}/${
                                deal.id
                              }`,
                              state: {
                                model: {
                                  id: globalVehicles?.filter(
                                    (x) => x.modelId == deal.modelId
                                  )[0]?.id,
                                  price: deal.price,
                                  modelName: deal.headline,
                                  variantId: deal.variantId,
                                },
                              },
                            })
                          }
                          color="primary"
                        >
                          Discover Offer
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Container>

        <OurVehicles />
      </main>
    </React.Fragment>
  );
}

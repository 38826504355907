import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Banner from '../shared/assets/warranty-banner.jpg'

const useStyles = makeStyles((theme) => ({
    bannerHeader: {
        backgroundColor: '#fc3',
        fontWeight: 700
    },
    info: {
        paddingBottom: '1rem'
    }
}));

const WarrantyPage = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                5 Year/150 000km Warranty
            </Typography>
            <Box mb={2}>
                <img style={{ width: '100%' }} src={Banner} />
            </Box>
            <Typography gutterBottom variant="h6" color="textPrimary" fontWeight="500">
                The Hyundai Automotive Vehicle and Parts Warranty will offer you complete peace of mind.
            </Typography>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Parts Warranty
                </Box>
                <Box>
                    <p>Hyundai Automotive warrants all parts and accessories supplied by Hyundai Automotive and fitted at the time of purchase of the new vehicle to be free of defects in 
                        material or workmanship under normal use for a period of 12 months.</p>

                    <p>Parts & Accessories purchased over the counter are covered from the invoice date by Hyundai Automotive’s 1 Year/20,000km warranty, whichever occurs first.</p>

                    <p>In the event that a genuine Hyundai Automotive part or accessory supplied by Hyundai Automotive proves to be defective in material or workmanship under normal use 
                        during the warranty period, Hyundai Automotive will repair or replace the part and/or accessory free of charge, excludes wear and tear parts.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Vehcile Warranty
                </Box>
                <Box>
                    <p>5 year 150 000km Warranty for all models ordered and registered from 11 Jan 2010.</p>
                    <strong>Duration of the Warranty</strong>
                    <p>Your new vehicle is protected by this Hyundai Automotive New Vehicle Warranty. This means the vehicle is guaranteed on the terms and conditions 
                        set out in the warranty policy against any defects relating to material, fitting or manufacturing fault under the Warranty for a 
                        period of 60 months from the date of delivery or 150 000km whichever comes first.</p>
                    <strong>Benefits to the Customer</strong>
                    <p>The warranty covers the cost of repair or replacement of defective parts to repair a material, assembly or manufacturing defect recognized by the 
                        manufacturer when carried out by a member of the Hyundai Automotive network. It also covers consequential damage to the vehicle resulting from the 
                        principal defect. It is up to the discretion of Hyundai Automotive, in consultation with the relevant Hyundai Automotive Network Member, to decide whether it is 
                        appropriate in the circumstances to repair or replace any fault part.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    What is Hyundai Automotive's Encore Warranty Extension?
                </Box>
                <Box>
                    <p>Pre-Owned Warranty Extension (Hyundai Automotive Warranty Extension)</p>
                    <p>Designed for the pre-owned Hyundai Automotive vehicle customer, Hyundai Automotive Warranty Extension is designed to give the client financial 
                        protection in the event of unexpected mechanical breakdown or failure. It covers the pre-owned Hyundai Automotive vehicle on expiry of the 
                        manufacturer warranty for a further 24 months or 100,000km whichever comes first.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Why Hyundai Automotive?
                </Box>
                <Box>
                    <ul>
                        <li>Hyundai Automotive Warranty Extension offers a product that will add the best value at the most cost effective level.</li>
                        <li>This has been designed to ensures peace of mind motoring.</li>
                    </ul>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    The benefits of Hyundai Automotive Warranty
                </Box>
                <Box>
                    <ul>
                        <li>Optimizes Resale Value of the Hyundai Automotive vehicle.</li>
                        <li>Purchasing a pre-owned Hyundai Automotive with all the values and guarantees that have come to be expected</li>
                        <li>A continuation of the Hyundai Automotive Manufacturers Warranty*.</li>
                        <li>The Hyundai Automotive Warranty Extension covers the cost of repair or replacement of defective parts and for potential Mechanical Product Defects.</li>
                        <li>Allows the Hyundai Automotive vehicle to be repaired using approved Hyundai Automotive parts and Hyundai Automotive trained technicians.</li>
                    </ul>
                    * 5yr/ 150,000km for vehicles retailed post 11 January 2010
                </Box>
            </div>
        </React.Fragment>
    );
}

export default WarrantyPage;
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URI, DEALERID } from "../shared/Constants";

export default function DealerDetailsController() {
  const [loading, setLoading] = useState(true);
  const [dealer, setDealer] = useState(null);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setDealer(null);

    let url = `${BASE_URI}/Dealers/DealerContactDetails/${DEALERID}`;

    axios({
      method: "GET",
      url: url,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        if (response?.data) {
          setDealer(response?.data);
        }
        setLoading(false);

        //TODO: Missing Fields
        //dealer.businessHours
        //dealer.services
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
    return () => cancel();
  }, [DEALERID]);

  return { loading, dealer };
}

import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Banner from '../shared/assets/maintenance-banner.jpg'

const useStyles = makeStyles((theme) => ({
    bannerHeader: {
        backgroundColor: '#fc3',
        fontWeight: 700
    },
    info: {
        paddingBottom: '1rem'
    }
}));

const MaintenancePage = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                Maintenance & Service Plans
            </Typography>
            <Box mb={2}>
                <img style={{width: '100%'}} src={Banner} />
            </Box>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Genuine Parts are specifically designed for Hyundai Automotive vehicles
                </Box>
                <Box>
                    Hyundai Automotive Genuine Parts, Lubricants and Chemicals are specifically designed for Hyundai Automotive vehicles, and are engineered to match the exact quality
                    standards of the original factory equipment specification used during the manufacture of your vehicle.
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Quality and Safety
                </Box>
                <Box>
                    <ul>
                        <li>The quality of materials guarantee the durability and longevity of Original Hyundai Automotive Parts. Tests are performed on each part to ensure 
                            that it meets Hyundai Automotive’s high standard in geometry, elasticity limits, breaking strains and resistance to knocks and temperature effects.
                        </li>
                        <li>
                            Hyundai Automotive uses all its know how to improve the safety of its vehicles. Thousands of tests are undertaken to give its parts better performance, 
                            durability and flexibility. We have for example increased the surface area of the windscreen and enhanced the headlight range to improve visibility.
                        </li>
                    </ul>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Innovation and Design
                </Box>
                <Box>
                    <ul>
                        <li>
                            Each body part plays a major role in vehicle reliability. The Hyundai Automotive industrial process is at the very forefront of technology and is 
                            subject to continuous technological checks during manufacture.
                        </li>
                        <li>
                            Hyundai Automotive’s genuine parts fit perfectly to your Hyundai Automotive which ensures that the original design of the vehicle can be maintained without disturbing 
                            ts aesthetics.
                        </li>
                    </ul>
                </Box>
            </div>
        </React.Fragment>
    )

}

export default MaintenancePage;
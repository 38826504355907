import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import VehicleController from "./VehicleController";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#e4dcd3",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  title: {
    fontSize: "1.5rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
}));

const OurVehicles = () => {
  const classes = useStyles();
  const { loading, error, vehicles, total } = VehicleController();

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          OUR VEHICLES
        </Typography>
        <Typography
          gutterBottom
          variant="h6"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          It all starts with a passion for life
        </Typography>
        <Box mb={3} mt={3}>
          <Typography align="center" classes={{ root: classes.bold }}>
            DISCOVER HYUNDAI
          </Typography>
        </Box>
        <Divider className={classes.dividerTitle} />
        <Grid container spacing={2}>
          {vehicles.map((vehicle, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link
                  underline="none"
                  component={RouterLink}
                  to={{
                    pathname: "/models/" + cleanModel(vehicle.title),
                    state: {
                      model: {
                        id: vehicle.id,
                        price: vehicle.price,
                        modelName: vehicle.title,
                      },
                    },
                  }}
                >
                  <Box className={classes.vehicleContainer}>
                    <Box className={classes.image}>
                      <ImageContainer
                        aspectRatio={16 / 8}
                        height="100%"
                        src={vehicle.image}
                        alt={vehicle.title}
                      />
                    </Box>
                    <Box></Box>
                    <Box
                      mt={2}
                      mb={0}
                      textAlign="center"
                      component="h5"
                      className={`${classes.title} ${classes.bold}`}
                    >
                      {vehicle.title}
                    </Box>
                    <Box
                      mt={0}
                      mb={0}
                      textAlign="center"
                      component="h5"
                      className={`${classes.title} ${classes.bold}`}
                    >
                      <Box component="span">From</Box>{" "}
                      <CurrencyValue value={vehicle.price} />
                    </Box>
                    <Box component="p" mt={0} mb={3} textAlign="center">
                      {"Single Model"}
                    </Box>
                    <Box mb={3} className={classes.btn}>
                      <Button
                        variant="contained"
                        gutterBottom
                        color="primary"
                        startIcon={<AddIcon />}
                      >
                        Discover
                      </Button>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default OurVehicles;

import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg" >
            <div className={classes.content}>
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                   TERMS & CONDITIONS
                </Typography>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500" >
                    Copyright
                </Typography>
                <p>
                    This document contains the terms and conditions for the use of Hyundai website and online services. By accessing any page on the Hyundai website or making use of any online services you agree to the following terms and conditions.
                </p>
                <p>
                    In terms of section 11 of the Electronic Communications and Transactions Act 25 of 2002, as amended (“ECTA”) these terms and conditions are binding and enforceable against all persons that access the Hyundai website or any part thereof or make use of any online services offered by Hyundai, a division of Motus Corporation (Pty) Ltd.
                </p>
                <p>
                    If you do not agree with these terms and conditions you must leave the Hyundai website and discontinue your use of the online services without delay, as further use will mean that you have accepted and agree to these terms and conditions.
                </p>
                <p>
                    Important terms which may limit Hyundai’s responsibility or involve some risk for you may be in bold. You must pay special attention to these terms and conditions.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Definitions and interpretation
                </Typography>
                <p>
                    “The Hyundai website” means the Hyundai Financial Services website owned and operated by Hyundai, a division of Motus Corporation (Pty) Ltd and located at https://www.hyundai.co.za, including any page, part or element thereof;
                    “Hyundai Automotive South Africa” or “HASA” or “Hyundai” means Hyundai Automotive South Africa (Pty) Ltd, a division of Motus Corporation (Pty) Ltd.
                </p>
                <p>
                    “Hyundai Financial Services” means Hyundai Financial Services, a division of Motus Corporation (Pty) Ltd. All products and services are administered by LiquidCapital (Pty) Ltd, (reg. no. 2001/012511/07) an Authorised Financial Services Provider. FSP Licence 6210.
                </p>
                <p>
                    “Online services” means the Hyundai website and any other service offered by Hyundai through electronic means, including but not limited to electronic billing platforms, online portals, and interactive customer tools, and shall include the content and information provided or exchanged as part of such services;
                </p>
                <p>
                    “User / you or your” means any person who enters or uses the Hyundai website or online services, notwithstanding the fact that such a person only visits the home page of the Hyundai website or online service portals;
                    References herein to the singular include the plural and vice versa; and
                    Hyperlinks have been used in these terms and conditions. The fact that some or all of the hyperlinks may be non-operational, shall not play a role in the determination of the validity and interpretation of these terms and conditions.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Disclaimer
                </Typography>
                <p>
                    Whilst every reasonable effort has been made by Hyundai, and its suppliers of content and information, to ensure the proper performance of the online services, the accuracy of the content, information and images and the reliability of the binary data on the online services, Hyundai, its affiliated companies, suppliers, or any of their employees, do not, to the full extent permitted by law, guarantee the availability or accuracy of the services, content, information and/or images offered on the online services.
                </p>
                <p>
                    Hyundai makes no representations or warranties, whether express or implied, and assumes no liability or responsibility for the proper performance of the online services and the online services are used at your own risk.
                </p>
                <p>
                    Hyundai does not warrant that the online services will meet your requirements, be uninterrupted, complete, timely, secure or error free.
                </p>
               
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Indemnities and Limitation of Liability
                </Typography>
                <p>
                    Hyundai shall not be liable to you or any third party, in any manner whatsoever, and you indemnify Hyundai accordingly, for any damage, loss liability, costs or expense whether direct, indirect or of a consequential nature that resulted from a breach of these terms and conditions by you or arising out of or in connection with the failure or delay in the performance of the online services or your use of the online services, other than in respect of losses caused by Hyundai Financial Services gross negligence or intentional misconduct.
                    Hyundai shall not be liable to you for any breach of these terms and conditions or failure to perform any obligations as a result of technical problems relating to the Hyundai website, act of God, government control, restrictions or prohibitions or other government act or omission, whether local or national, act of default of any supplier, agent or sub-contractor, industrial disputes or any other cause beyond Hyundai Financial Services’ reasonable control.
                </p>
                
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Permitted use and License
                </Typography>
                <p>
                    You agree and confirm that you are over the age of 18 years and are authorised to access, supply and/or make changes to any personal information and associated settings on any of the online services available to you.
                    In the event that you are not the vehicle owner and/or are not authorised to make use of the relevant online services, you declare that you have obtained the necessary consent to use said information.
                </p>
                <p>
                    You are only permitted and may only use, access, browse, view, amend, download and print the content and details of the online services for lawful and legal purposes.
                </p>
                <p>
                    You warrant that you shall not:
                </p>
                <p>
                    use the online services for commercial and non-private purposes; and
                    use the online services to receive or transmit material which is in violation of any law or regulation, which is hate-speech, offensive, obscene, threatening, racist, defamatory, in breach of confidence, or in breach of any intellectual property rights;
                </p>
                <p>
                    The caching of the online services shall only be allowed if:
                </p>
                <p>
                    the purpose of the caching is to make the onward transmission of the content from the online services more efficient;
                </p>
                <p>
                    the cached content is not modified in any manner whatsoever;
                </p>
                <p>
                    the cached content is updated at least every 12 (twelve) hours; and
                </p>
                <p>
                    the cached content is removed or updated when so required by Hyundai.
                </p>
                <p>
                    If you use content from the online services in breach of these terms and conditions:
                </p>
                <p>
                    Hyundai reserves the right to claim damages from you;
                </p>
                <p>
                    Hyundai reserves the right to institute criminal proceedings against you; and
                </p>
                <p>
                    Hyundai shall not be liable, in any manner whatsoever, for any damage, loss or liability that resulted from the use of such content by you or any third party who obtained any content from you.
                </p>
                <p>
                    Hyperlinks to the online services from any other source shall be directed at the home page of the Hyundai website. Links beyond the Hyundai website home page may only be used with Hyundai prior written consent.
                </p>
                <p>
                    Users may quote small and reasonable amounts of content available from the online services only if such content is placed in inverted commas and the source acknowledged.
                </p>
                <p>
                    No person may, without the prior written consent of Hyundai, frame the online services in any manner whatsoever.
                </p>
                <p>
                    Apart from bona-fide search engine operators, no person may use or attempt to use any technology or applications (including web crawlers, robots or web spiders) to search, collect or copy content from the online services for any purposes, without the prior written consent of Hyundai Financial Services.
                </p>
                <p>
                    E-mail addresses, names, telephone numbers and fax numbers published on the online services may not be incorporated into any database used for electronic marketing or similar purposes. No permission is given or should be implied that information in/on the online services may be used to communicate unsolicited communications to Hyundai and all of Hyundai’s rights are reserved.
                </p>
                <p>
                    All licenses and/or permissions granted in terms of these terms and conditions are provided on a nonexclusive and non-transferable basis and may be terminated or cancelled by Hyundai Financial Services at any time without prior notice or reason.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Intellectual Property Rights
                </Typography>
                <p>
                    All intellectual property on the Hyundai website and online services, including but not limited to content, trademarks, domain names, patents, design elements, software, source code, meta tags, databases, text, graphics, icons and hyperlinks are the property of or licensed to Hyundai and as such, are protected from infringement by domestic and international legislation and treaties. Subject to the rights provided to you in these terms and conditions, all of the rights to intellectual property on the Hyundai Financial Services website and online services are expressly reserved.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Software and Equipment
                </Typography>
                <p>
                    It is the responsibility of the User to acquire and maintain, at his/her own expense, the necessary computer hardware, software, communication lines and Internet access accounts required to access the Internet and the online services and/or download content from the Hyundai website and the online services.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Privacy Statement
                </Typography>
                <p>
                    Hyundai shall take all reasonable steps to protect the personal information of Users and is committed to respecting the privacy of your personal information. For the purpose of these terms and conditions, “personal information” shall be defined as detailed in the Protection of Personal Information Act, 2013.
                    As and when necessary, Hyundai may electronically collect, store, disclose and/or use the following of your personal information:
                </p>
                <ul>
                    <li>name and surname;</li>
                    <li>contact numbers;</li>
                    <li>IP address; and/or geographical location.</li>
                    <li>e-mail address;</li>
                    <li>non-personal browsing habits and click patterns;</li>
                </ul>
                <p>
                    Hyundai collects stores and uses the abovementioned information in order for Hyundai to:
                    communicate requested information to you;
                    respond to queries, responses or complaints submitted by you;
                </p>
                <p>
                    process orders or applications for Hyundai Financial Services products and/or services;
                    create products or services that may meet your future requirements; <br/><br/>
                    compile non-personal statistical information about browsing habits, click-patterns and access to the online services. <br/><br/>
                    Personal information detailed above is collected and/or stored either electronically by the use of “cookies” or is provided voluntarily with your knowledge and consent. You can determine any use of cookies through your browser settings but note that turning off cookies may cause certain features of the online services or Hyundai website to be  unavailable to you. <br/><br/>
                    Hyundai may further collect non-personal information, for example, your IP address, the date and time of your visits to the Hyundai website, and browser history, to recognise you during any subsequent visits to the Hyundai website and/or use of the online services. Hyundai may further use this non-personal information to develop future products and/or services to meet your requirements and needs. <br/><br/>
                    Hyundai owns and retains all rights to non-personal statistical information collected and compiled by Hyundai. <br/>
                    As and when necessary, Hyundai may use and/or disclose your personal information collected: <br/>
                    in order to comply with legal and regulatory requirements and applicable laws; <br/>
                    to either credit grantors and/or credit bureaux and/or banks and/or other financial institutions in order to ascertain information relating to your creditworthiness and for <br/> fraud prevention purposes and in order to process any payment transactions where necessary; <br/>
                    to attorneys and/or debt collection agencies if you breach any agreements with Hyundai Financial Services; <br/>
                    to Hyundai agents and/or consultants and/or trade partners, but only to the extent necessary and in order to provide Hyundai products, services and the online services to you; <br/>
                    <br/>
                    to consumer research companies for the purposes of conducting research on improving Hyundai products, services and the online services;
                    for the purposes of us publishing a directory containing the name, vehicle details, the applicable contact number and the applicable email address of Hyundai subscribers; and <br/>
                    <br/>
                    for the purposes of Hyundai informing or sending information to you about any new services or products offered by Hyundai and/or by any of Hyundai agents and/or consultants and/ or trade partners which we consider may be of interest to you. Hyundai will comply with the direct marketing provisions of the Consumer Protection Act, 68 of 2008 (“CPA”) and the CPA regulations, including the provisions relating to the direct marketing registry. <br/><br/>
                    By using the online services and the Hyundai website you understand that Hyundai may, where necessary and where permitted by law, monitor your browser habits, online chat or e-mails for business purposes, including for purposes of quality control, training, marketing and improving the online services. Hyundai agrees that it will not disclose any  personal information that could be used to identify you. <br/><br/>
                    Hyundai reserves the right to amend this privacy statement if required to do so due to a change in any personal information or privacy laws.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Hyperlinks to third party sites
                </Typography>
                <p>
                    In the event that the online services contain hyperlinks to third party sites not controlled by Hyundai (“target sites”), Hyundai is not responsible for the content of, or the services offered by those sites. The hyperlink(s) are provided solely for your convenience and should not be construed as an express or implied endorsement by Hyundai of the site(s) or the products or services provided therein. You access those sites and use their products and services solely at your own risk Hyundai recommends that you check the policy of the target sites if you have any concerns or questions.<br/><br/>
                    Hyundai shall not be liable, in any manner whatsoever, for any damage, loss liability, costs or expense whether direct, indirect or of a consequential nature that resulted from the use and access to target sites and the content on such target sites. Persons that wish to link to content beyond the home page of the Hyundai website and/or online services without Hyundai’s prior written consent shall do so at their own risk and indemnify Hyundai against any loss, liability or damage that may result from the use of content from the Hyundai website and online services, if such content was accessed through a hyperlink not directed at the home page of the Hyundai website and/or online services. Hyundai non- liability for deep linking is based on the fact that deep links bypass these terms and conditions.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Security
                </Typography>
                <p>
                    Hyundai shall take all reasonable steps to secure the content of the online services and the information provided by and collected from Users from unauthorised access and/or disclosure, however, Hyundai makes no warranties or representations in this regard.<br/><br/>
                    While Hyundai has implemented appropriate and reasonable security technologies and practices, please be aware that internet communications are insecure unless they have been encrypted and/or appropriate security safeguards implemented. Accordingly, Hyundai assumes no responsibility or liability of any nature whatsoever for the interception or loss of personal information beyond Hyundai’s control.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Disclosures required by section 43 of the ECTA
                </Typography>
                <p>
                    Access to the services, content, software and downloads available from the online services may be classified as “electronic transactions” as defined in terms of ECTA and you therefore may have the rights detailed in ECTA. Accordingly, Hyundai Automotive South Africa provides the following information:<br/><br/>
                    The full name and legal status of the online services owner: Hyundai Automotive South Africa (Proprietary) Limited, Registration Number: 1999/0159934/07 ; Street address: Cnr Norman Rd & Lucas Lane, Bedfordview, 2007, South Africa; Postal address: ; Physical address for receipt of legal service: 1 van Buuren Road, Bedfordview, 2007, South Africa; Main business: Hyundai Automotive South Africa is an automotive motor vehicle importer and distributor; The website address of the Hyundai Financial Services website is: https://www.hyundai.co.za; The official e-mail address of the Hyundai Financial Services website is: info@hyundai.co.za; The official contact number of Hyundai Financial Services is: 010 248 8000. Access to the Hyundai South Africa website is provided free of charge;<br/><br/>
                    Alternative dispute resolution: Subject to urgent and/or interim relief, all disputes regarding access to online services; the inability to access the online services; the services and content available from the online services; or these terms and conditions, shall be referred to Hyundai Automotive South Africa ;<br/><br/>
                    You may return goods or services as detailed in ECTA, the Consumer Protection Act and Hyundai Automotive South Africa’s policies; and xii. Users may lodge complaints concerning the online services with Hyundai Automotive South Africa at info@hyundai.co.za.<br/><br/>
                    Access to the services, content, software and downloads available from the online services may be classified as “electronic transactions” as defined in terms of ECTA and you therefore may have the rights detailed in ECTA.<br/><br/>
                    Accordingly, Hyundai Financial Services provides the following information: The full name and legal status of the online services owner: Hyundai Financial Services, a division of Motus Corporation (Pty) Ltd. All products and services are administered by LiquidCapital (Pty) Ltd, (reg. no. 2001/012511/07) an Authorised Financial Services Provider. FSP Licence 6210; ii. Street address: Building 1, Block A, The Bridge Office Park, 5 Boeing Road East, Elma Park, Edenvale, 1609, South Africa; Postal address: PO Box 851, Edenvale, 1610, Gauteng, South Africa; Physical address for receipt of legal service: Building 1, Block A, The Bridge Office Park, 5 Boeing Road East, Elma Park, Edenvale, 1609, South Africa;<br/><br/>
                    Main business: Hyundai Financial Services is a motor vehicle financial value added products and services provider;<br/><br/>
                    The website address of the Hyundai Financial Services website is: https://vaps.hyundai.co.za;<br/><br/>
                    The official e-mail address of the Hyundai Financial Services website is: financialservices@hyundai.co.za;<br/><br/>
                    The official contact number of Hyundai Financial Services is: 0861 112 481.<br/><br/>
                    Access to the Hyundai Financial Services website is provided free of charge;<br/><br/>
                    Alternative dispute resolution: Subject to urgent and/or interim relief, all disputes regarding access to online services; the inability to access the online services; the services and content available from the online services; or these terms and conditions, shall be referred to Hyundai Financial Services ;<br/><br/>
                    You may return goods or services as detailed in ECTA, the Consumer Protection Act and Hyundai Financial Services’ policies; and
                    Users may lodge complaints concerning the online services with Hyundai Financial Services at financialservices@hyundai.co.za.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Changes and Amendments
                </Typography>
                <p>
                    To the extent permitted by law, Hyundai reserves the right, in its sole and absolute discretion, to do any of the following, at any time without prior notice or justification:
                    change these terms and conditions;<br/><br/>
                    change the content and/or services available from the online services;<br/><br/>
                    alter and/or discontinue any aspect of the online services; and/or
                    change the software and hardware required to access and use the online services.<br/><br/>
                    The amended versions of the terms and conditions and online services will be displayed in the same media as these terms and conditions. By continuing to use the online services, you agree and understand that you will be bound by the amended terms and conditions.<br/><br/>
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    HYUNDAI BREAKPAD SPECIAL TERMS AND CONDITIONS
                </Typography>
                <p>
                    Only applicable to passenger vehicles needing front brake pads replaced.<br/><br/>
                    50% discount on parts and labour is only relevant for front brake replacements and does not include disc replacements /disc skimming or any other brake repair.<br/><br/>
                    Only applies to workshop sales and not ‘over the counter’ parts sales<br/><br/>
                    Only applicable to retail jobs<br/><br/>
                    End date – 31 December 2020
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    SUNDOWNS SUPPORTER PLAYER OF THE MONTH TERMS AND CONDITIONS
                </Typography>
                <p>
                    These Terms and Conditions will govern your participation in the Hyundai/Sundowns March Player of the Month Competition. You are deemed to have read and consented to the terms and conditions and agree to be bound thereto before entering the Competition. Information regarding the Competition that is published on authorised advertising material will also form part of the terms and conditions of the Competition. The Organisers may in their sole discretion amend these terms and conditions at any time, without notice, and such amendments shall be deemed to have taken effect from the date of publication of the revised terms and conditions on the Organisers’ website www.hyundai.co.za.<br/><br/>
                    The Competition is organised by Hyundai Automotive South Africa (Pty) Limited, Registration Number: 1999 / 015934 / 07 and/or its agencies and any other organisers (the" Organisers") if any.<br/><br/>
                    This Competition will run from 19 April 2021 from the time the competition is posted on Facebook, Twitter, and Instagram to 22 April 2021 at 12pm (both dates inclusive). Entries received after the closing date and time will not be considered.<br/><br/>
                    The duration of the Competition may be extended or curtailed at the discretion of the Organisers.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    WHO CAN ENTER?
                </Typography>
                <p>
                    Participants must be 18 years or older (or if a minor, must be duly assisted by their legal guardians) be a South African citizen or permanent resident in the Republic of South Africa (“RSA”), and have a valid identity document or passport and reside in the RSA during the period of the Competition (“Participants”/” you”/” your”).<br/><br/>
                    Participants may not enter or receive a prize if they are directors, members, partners, employees, agents of or consultants of the Organisers, their subsidiaries, holding companies, divisions and/or associated companies or of the advertising or promotion agencies or any other person who directly or indirectly controls or is controlled by the Organisers, or their spouses, life partners, immediate family members or business partners.<br/><br/>
                    By entering this Competition, all Participants agree to be bound by these terms and conditions, and the Organisers’ decision regarding any issue with the Competition will be final and binding and no correspondence will be entered into.<br/><br/>
                    No responsibility will be accepted for any entry that is not delivered, received or is delayed or damaged.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    HOW TO ENTER
                </Typography>
                <p>
                    The competition mechanic states that you need to:<br/><br/>
                    Watch the Sundowns March player of the month video posted on Hyundai account on 19 April 2021.<br/><br/>
                    When you see your player of the month for March, take a screenshot of the ball inside the outline<br/><br/>
                    Post the image to your Story and tag @HyundaiSA<br/><br/>
                    If your player of the month wins, then you could stand a chance to win.<br/><br/>
                    One entry per person will be counted. The image you submit to your social media story will count as your entry.<br/><br/>
                    The winner will be randomly selected by the Organisers and the Organiser’s decision is final.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    PRIZES
                </Typography>
                <p>
                    The prize consists of a signed Sundowns shirt by the player who wins Sundowns Player of the month for March.
                    One winner will be selected.<br/><br/>
                    The winner will be contacted before the end of April, to confirm clothing sizes if deemed necessary, as well as a delivery date and delivery address for the prize. If, however, if they cannot be reached after 3 attempts have been made during business hours within a period of 72 hours for any reason whatsoever, the prize will be forfeited, and another winner will be randomly selected in accordance with the competition rules.<br/><br/>
                    Actual prizes may differ from those depicted in the promotional posters or tv advertisements.<br/><br/>
                    The prize is not exchangeable for cash and not transferable. The winner will have to claim their prize within 30 days of the notification having taken place and at that time identify themselves with a bar coded South African ID book and sign a receipt for any prize received. Failure to claim the prize or a refusal or inability to comply herewith will disqualify the winner and a new winner will be drawn in their place at the sole discretion of the Organisers.<br/><br/>
                    The Organisers reserve the right to change the prize, should they deem it necessary, to a prize of similar economic value.<br/><br/>
                </p>

                <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>

            </div>
        </Container>
    )
}

export default Terms
import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainerUsedVehicle } from "../shared/ImageContainerUsedVehicle";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import VehicleController from "./VehicleController";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Modal } from "@material-ui/core";
import CarProfileContainer from "../carProfile/UsedCar";
import { useHistory } from "react-router-dom";
import { FinanceContext } from '../shared/contexts/FinanceContext';
import ComingSoon from "../shared/assets/comingsoon.jpg";

const useStyles = makeStyles((theme) => ({
  filterLoadingContainer: {
    height: "576px",
    position: "absolute",
    width: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "10",
    boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
    filter: "blur(10px)",
  },
  full: {
    width: "100%",
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#e4dcd3",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  carddividerTitle: {
    backgroundColor: "#e4dcd3",
    width: "100%",
    marginBottom: "10px",
    height: "3px",
    margin: "5px auto",
  },
  bold: {
    fontWeight: 700,
  },
  lighter: {
    fontWeight: "lighter",
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },
  specs: {
    padding: "0px!important",
    margin: "2px 0px!important",
    fontSize: "14px",
  },
  price: {
    fontSize: "1.7rem",
    textAlign:'right',
    "&>span": {
      fontWeight: 400,
    },
  },
  pricepm: {
    fontSize: "0.8rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    background: "white",
    border: "1px solid black",
  },
  btn2: {
    minWidth: "100%",
  },
  image: {

    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
 
  carsLoadContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CarsLoad: {
    margin: "auto",
  },
}));

const UsedVehicles = () => {
  
  const history = useHistory();
  const classes = useStyles();
  const [makes, setMake] = useState([]);
  const [priceRages, setPriceRanges] = useState([]);
  const [activeMake, setActiveMake] = useState(null);
  const [activeModel, setActiveModel] = useState(null);
  const [activeProvince, setActiveProvince] = useState(null);
  const [activePriceRange, setActivePriceRange] = useState(null);
  const [activeYear, setActiveYear] = useState(null);
  const [activeTransmission, setActiveTransmission] = useState(null);
  const [activeNewUsed, setActiveNewUsed] = useState(null);
  const [activeActiveBodyType, setActiveBodyType] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [activeVehicle,setActiveVehicle] = useState(null);
    const [activePrices, setActivePrices] = useState([]);
    let { setGlobalModel } = useContext(FinanceContext);

  const { enqueueSnackbar } = useSnackbar();
  const [searchLoading, setSearchLoading] = useState(false);

  const { loading, error, vehicles, total } = VehicleController();
  useEffect(()=>{
  },[activeVehicle])
  useEffect(() => {
    if (vehicles.length > 0) {
      setFilteredData(vehicles);
      setMake(Array.from(new Set(vehicles.map((m) => m.brand))));
      const prices = [...new Set(vehicles.map((m) => m.price))];
      CalcPriceRanges(prices, true);
    }
  }, [vehicles]);
  const onSetFilterCriteria =(make,model,priceRange,province,year,transmission,newUsed,bodytype)=>{
    setSearchLoading(true);
    setActiveMake(make);
    setActiveModel(model);
    setActivePriceRange(priceRange);
    setActiveProvince(province);
    setActiveYear(year);
    setActiveTransmission(transmission);
    setActiveNewUsed(newUsed);
    setActiveBodyType(bodytype);
    setShowFilteredData(true);
    setActiveVehicle(null);
    const filteredData = vehicles
      .filter((v) => {
        return activeMake
          ? v.brand.toUpperCase() === activeMake.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeModel
          ? v.model.toUpperCase() === activeModel.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activePriceRange
          ? v.price >= activePriceRange.min && v.price <= activePriceRange.max
          : v;
      })
      .filter((v) => {
        return activeProvince
          ? v.province.toUpperCase() === activeProvince.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeYear ? v.year === activeYear : v;
      })
      .filter((v) => {
        return activeTransmission
          ? v.transmission.toUpperCase() === activeTransmission.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeNewUsed
          ? v.newUsed.toUpperCase() === activeNewUsed.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeActiveBodyType
          ? v.bodyType.toUpperCase() === activeActiveBodyType.toUpperCase()
          : v;
      });
    if (filteredData?.length > 0) {
      const prices = [...new Set(filteredData.map((m) => m.price))];
      setPriceRanges(CalcPriceRanges(prices));
    }
    setFilteredData(filteredData);
    setSearchLoading(false);
  }
  useEffect(() => {
    setSearchLoading(true);
    const filteredData = vehicles
      .filter((v) => {
        return activeMake
          ? v.brand.toUpperCase() === activeMake.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeModel
          ? v.model.toUpperCase() === activeModel.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activePriceRange
          ? v.price >= activePriceRange.min && v.price <= activePriceRange.max
          : v;
      })
      .filter((v) => {
        return activeProvince
          ? v.province.toUpperCase() === activeProvince.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeYear ? v.year === activeYear : v;
      })
      .filter((v) => {
        return activeTransmission
          ? v.transmission.toUpperCase() === activeTransmission.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeNewUsed
          ? v.newUsed.toUpperCase() === activeNewUsed.toUpperCase()
          : v;
      })
      .filter((v) => {
        return activeActiveBodyType
          ? v.bodyType.toUpperCase() === activeActiveBodyType.toUpperCase()
          : v;
      });
    if (filteredData?.length > 0) {
      const prices = [...new Set(filteredData.map((m) => m.price))];
      setPriceRanges(CalcPriceRanges(prices));
    }
    setFilteredData(filteredData);
    setSearchLoading(false);
  }, [
    activeMake,
    activeModel,
    activeProvince,
    activePriceRange,
    activeYear,
    activeTransmission,
    activeNewUsed,
    activeActiveBodyType,
    vehicles,
  ]);
  useEffect(()=>{
    if(activeVehicle){
      window.history.replaceState(null,'Car Profile',`/stock/${activeVehicle?.stockId}`);
    }else{
      window.history.replaceState('/used-vehicles');
    }

  },[activeVehicle])

  const GetList = (
    make,
    model,
    priceRange,
    province,
    year,
    transmission,
    newUsed,
    body
  ) => {
    return vehicles
      .filter((v) => {
        return make ? v.brand.toUpperCase() === make.toUpperCase() : v;
      })
      .filter((v) => {
        return model ? v.model.toUpperCase() === model.toUpperCase() : v;
      })
      .filter((v) => {
        return priceRange
          ? v.price >= priceRange.min && v.price < priceRange.max
          : v;
      })
      .filter((v) => {
        return province
          ? v.province.toUpperCase() === province.toUpperCase()
          : v;
      })
      .filter((v) => {
        return year ? v.year === year : v;
      })
      .filter((v) => {
        return transmission
          ? v.transmission.toUpperCase() === transmission.toUpperCase()
          : v;
      })
      .filter((v) => {
        return newUsed ? v.newUsed.toUpperCase() === newUsed.toUpperCase() : v;
      })
      .filter((v) => {
        return body ? v.bodyType.toUpperCase() === body.toUpperCase() : v;
      });
  };
  const CalcPriceRanges = (list, update) => {
    const min = Math.min.apply(Math, list);
    const max = Math.max.apply(Math, list);
    let step = 0;
    var ranges = [];

    while (true) {
      if (min > step) {
        if (step < 100000) {
          step += 100000;
        } else {
          step += 100000;
        }
      } else {
        if (step >= 100000) {
          ranges.push({ min: step - 100000, max: step });
        } else {
          if (step === 0) {
            step += 100000;
            ranges.push({ min: 0, max: step });
          } else {
            ranges.push({ min: step - 100000, max: step });
          }
        }
        break;
      }
    }
    while (true) {
      if (step < max) {
        step += 100000;
        ranges.push({ min: step - 100000, max: step });
      } else {
        break;
      }
    }

    return ranges;
  };

  const onClearFilter = () => {
    window.location.reload();
  };

  const buildName = (vehicle) => {
    if (!vehicle) return "";

    return vehicle.brand + " " + vehicle.model;
    };


    const applyOnline = (vehicle) => {
        setGlobalModel(vehicle);

        history.push(`/apply-for-finance`)
    };

  return (
    
    <MuiThemeProvider>
    {activeVehicle?<CarProfileContainer searchLoading={searchLoading} activeVehicle={activeVehicle} onFilterData={onSetFilterCriteria}/>:
      <Container maxWidth="lg">
        <div className={classes.content}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            color="textPrimary"
            fontWeight="500"
          >
            USED VEHICLES
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            align="center"
            color="textPrimary"
            fontWeight="500"
          >
            Discover our large range of used vehicles
          </Typography>
      
          <Divider className={classes.dividerTitle} />
          <Grid container md={12} className={classes.flex}>
            <Grid md={2} xs={12}>
              {searchLoading && (
                <div className={classes.filterLoadingContainer} />
              )}
              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="All Makes"
                onChange={(e) => setActiveMake(e.target.value)}
              >
                {makes.map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Any model"
                onChange={(e) => setActiveModel(e.target.value)}
                onCompositionStart={() => setActiveModel(null)}
              >
                {Array.from(
                  new Set(GetList(activeMake).map((v) => v.model))
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="All prices ranges"
                onChange={(e) => setActivePriceRange(e.target.value)}
              >
                {priceRages.map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {`R${option?.min} - R${option?.max - 1}`}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="All Provinces"
                onChange={(e) => setActiveProvince(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(activeMake, activeModel, activePriceRange).map(
                      (v) => v.province
                    )
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Any year"
                onChange={(e) => setActiveYear(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince
                    ).map((v) => v.year)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Man / Auto"
                onChange={(e) => setActiveTransmission(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince,
                      activeYear
                    ).map((v) => v.transmission)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Used / Demo"
                onChange={(e) => setActiveNewUsed(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince,
                      activeYear,
                      activeTransmission
                    ).map((v) => v.newUsed)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Body type"
                onChange={(e) => setActiveBodyType(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince,
                      activeYear,
                      activeTransmission,
                      activeNewUsed
                    ).map((v) => v.bodyType)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <Box mb={3} className={classes.flex}>
                <Button
                  disabled={loading}
                  gutterBottom
                  variant="contained"
                  color="primary"
                  className={classes.full}
                  onClick={
                    !showFilteredData
                      ? () => setShowFilteredData(true)
                      : onClearFilter
                  }
                >
                  {!searchLoading
                    ? showFilteredData
                      ? "RESET"
                      : "SEARCH"
                    : ""}
                  {searchLoading && <CircularProgress />}
                </Button>
              </Box>
            </Grid>
            {loading || searchLoading ? (
              <Grid
                classaName={classes.carsLoadContainer}
                container
                spacing={2}
                md={10}
              >
                <CircularProgress className={classes.CarsLoad} size={200} />
              </Grid>
            ) : (
              <Grid container spacing={2} md={10}>
                {(!showFilteredData ? vehicles : filteredData).map(
                  (vehicle, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                      {/*}  <Link
                          underline="none"
                          component={RouterLink}
                          to={"/stock/" + vehicle.stockId}
                    >*/}
                          <Box className={classes.vehicleContainer}>
                            <Box className={classes.image}>
                              <ImageContainerUsedVehicle
                                aspectRatio="auto auto"
                                height="222px"
                                position="relative"
                                src={vehicle.image ? vehicle.image : ComingSoon }
                                alt={buildName(vehicle.title)}
                                
                              />
                            </Box>
                            <Box></Box>
                            <Box
                              mt={2}
                              mb={0}
                              textAlign="left"
                              component="h5"
                              className={`${classes.title} ${classes.bold}`}
                            >
                              {buildName(vehicle.title)}
                            </Box>

                            <Grid
                              item
                              md={12}
                              mt={0}
                              mb={0}
                              className={`${classes.flex} ${classes.bold}`}
                            >
                              <Grid item xs={12} sm={6} md={8}  >
                              <p className={classes.specs}>{vehicle.brand}</p>
                                <p className={classes.specs}>{vehicle.model}</p>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                component="h5"
                                justify="flex-end"
                                className={`${classes.price} ${classes.bold}`}
                              >
                                <CurrencyValue value={vehicle.price} className={`${classes.price} ${classes.bold}`}/>

                               
                              </Grid>
                            </Grid>
                            <Divider className={classes.carddividerTitle} />
                            <Box
                              mt={0}
                              mb={0}
                              textAlign="left"
                              component="p"
                              className={`${classes.title} ${classes.lighter} ${classes.flex}`}
                            >
                              <p className={classes.specs}>
                                {vehicle.year} | {vehicle.mileage} km |{" "}
                                {vehicle.colour} |{" "}
                                {vehicle.transmission == "M"
                                  ? "Manual"
                                  : "Auto"}
                              </p>
                            </Box>

                            <Box mb={3} className={classes.flex}>
                              <Button
                                variant="contained"
                                gutterBottom
                                className={classes.btn1}
                                onClick={()=>setActiveVehicle(vehicle)}
                              >
                                VIEW
                              </Button>
                              <Button
                                variant="contained"
                                gutterBottom
                                className={classes.btn}
                                        color="primary"
                                        onClick={() => applyOnline(vehicle)}
                              >
                                APPLY ONLINE
                              </Button>
                            </Box>
                          </Box>
                        {/*</Link>*/}
                      </Grid>
                    );
                  }
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </Container>}
    </MuiThemeProvider>
  );
};

export default UsedVehicles;

import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URI, DEALERMAKEID } from "../shared/Constants";
import { FinanceContext } from "../shared/contexts/FinanceContext";

export default function VehicleController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [total, setTotal] = useState(false);
  let { setGlobalVehicles } = useContext(FinanceContext);
  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);
    setVehicles([]);

    const params = {
      pageNumber: 1,
      dealerId: 286,
      pageSize: 1000,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/dealermodels`,
      params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        let hyundaiVehicles = response.data?.list?.filter(
          (x) => x.makeId == DEALERMAKEID
        );

        setVehicles(hyundaiVehicles);
        setGlobalVehicles(hyundaiVehicles);
        setTotal(hyundaiVehicles.length > 0);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, vehicles, total };
}

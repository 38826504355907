import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import * as tmpDeals from './tmpDeals'
import PaymentDetails from '../shared/PaymentDetails'
import FinanceOptionsComponent from './FinanceOptionsComponent'
import { ImageContainer } from '../shared/ImageContainer'
import Carousel from '../homePage/Carousel'
import { SpecificationsComponent } from '../shared/SpecificationsComponent'
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CurrencyValue } from '../shared/TextMask';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import CheckIcon from '@material-ui/icons/Check';
import ApplySteps from '../shared/ApplySteps';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import VehicleController from "../usedVehicles/VehicleController";
import ComingSoon from "../shared/assets/comingsoon.jpg";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    largeButton: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        '& h6': {
            fontSize: '28.8px'
        }
    },
    buttonIconLg: {
        fontSize: '5rem',
        color: '#e4dcd3'
    },
    sectionPadding: {
        margin: '3rem 0'
    },
    paragraphPadding: {
        padding: '1.5rem 3rem !important'
    },
    heading: {
        borderBottom: '5px solid #e4dcd3',
        textTransform: 'capitalize',
        paddingBottom: '.5rem',
        fontWeight: '700',
        display: 'inline-block'
    },
    yellowBorder: {
        borderBottom: '5px solid #e4dcd3',
    },
    secondaryHeading: {
        textTransform: 'uppercase',
        paddingBottom: '1rem',
        fontWeight: '400'
    },
    slideMargin: {
        width: 'calc(100% - 20px)',
        height: 'calc(100%)',
        left: '10px',
        top: '10px',
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem'
    },
    yellow: {
        color: '#e4dcd3'
    },
    specs: {
        '& .specs': {
            borderBottom: '0 !important',
            marginTop: '-25px'
        },
    },
    formControl: {
        minWidth: 400,
        '@media (max-width: 450px)': {
            minWidth: '100%'
        }
    },
    headerLine: {
        height: '2px',
        width: '100%',
        backgroundColor: '#e4dcd3',
        padding: '0 !important'
    },
    smallHeading: {
        fontSize: '1.3rem'
    },
    lighter: {
        fontWeight: 'lighter',
        fontSize: '1.0rem',
        padding: '0 !important'
    },
    stepPaddingColours: {
        marginLeft: "1em",
        marginRight: "1em",
        marginTop: "0.5em",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    colorsContainer: {
        display: 'flex',
        '& > div:first-child': {
            marginLeft: 0
        }
    },
    colourName: {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        marginTop: '0.6rem'
    },
    colourPrice: {
        fontWeight: 700
    },
    stepOneButtons: {
        display: 'flex',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '& > a': {
                marginLeft: '0 !important',
                marginRight: '0 !important'
            }
        }
    },
    topDetailSection: {
        '@media screen and (max-width: 959px)': {
            display: 'flex',
            flexDirection: 'column',
            '& > div:first-child': {
                order: 2,
                marginTop: '2rem'
            },
            '& > div:nth-child(2)': {
                order: 3
            },
            '& > div:nth-child(3)': {
                order: 4
            },
            '& > div:nth-child(4)': {
                marginTop: '-1.5rem'
            }
        }
    },
    darkgreyleft:{
        fontWeight:'bold',
        borderBottom: '#cccccc  1px solid',
    },
    darkgreyright:{
        textAlign:'right',
        borderBottom: '#cccccc  1px solid',
    },
    flex: {
        display: 'flex',
        flexDirection:'row!important',
        justifyContent:'space-between',
    },
    btn: {
   
        alignItems: 'start',
        marginTop:'2%',
        width:'45%',
    },
    btn1: {
     
        alignItems: 'start',
        marginTop:'2%',
        width:'45%',
        background:'white',
        border:'1px solid black',
    },
    btn2: {
   
        alignItems: 'start',
        marginTop:'2%',
        width:'100%',
    },
}));

export default function CarProfileContainer({searchLoading,activeVehicle,onFilterData}) {
    const classes = useStyles();
    let { stockId } = useParams();
    const history = useHistory();

    let vehicleProfile;

    const [carProfile, setCarProfile] = useState(vehicleProfile);
    const [compLoading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [financeOptions, setFinanceOptions] = useState([])
    const [financeDetails, setFinanceDetails] = useState({})
    const [modelData, setModelData] = React.useState([]);
    const [images, setImages] = useState([]);

    let { setGlobalFinance, setGlobalVehicle, setGlobalColor, setGlobalVariantId, setGlobalModel, globalColor } = useContext(FinanceContext);
    const { loading, error, vehicles, total } = VehicleController();
    const [makes, setMake] = useState([]);
    
    const [priceRages, setPriceRanges] = useState([]);
    const [activeMake, setActiveMake] = useState(null);
    const [activeModel, setActiveModel] = useState(null);
    const [activeProvince, setActiveProvince] = useState(null);
    const [activePriceRange, setActivePriceRange] = useState(null);
    const [activeYear, setActiveYear] = useState(null);
    const [activeTransmission, setActiveTransmission] = useState(null);
    const [activeNewUsed, setActiveNewUsed] = useState(null);
    const [activeActiveBodyType, setActiveBodyType] = useState(null);
  const [showFilteredData, setShowFilteredData] = useState(false);
    const values =
    {
        model: "",
        modelId: "",
    };

    useEffect(() => {
        if (vehicles.length > 0) {
          setMake(Array.from(new Set(vehicles.map((m) => m.brand))));
          const prices = [...new Set(vehicles.map((m) => m.price))];
          CalcPriceRanges(prices, true);
        }
      }, [vehicles]);

      const CalcPriceRanges = (list, update) => {
        const min = Math.min.apply(Math, list);
        const max = Math.max.apply(Math, list);
        let step = 0;
        var ranges = [];
    
        while (true) {
          if (min > step) {
            if (step < 100000) {
              step += 100000;
            } else {
              step += 100000;
            }
          } else {
            if (step >= 100000) {
              ranges.push({ min: step - 100000, max: step });
            } else {
              if (step === 0) {
                step += 100000;
                ranges.push({ min: 0, max: step });
              } else {
                ranges.push({ min: step - 100000, max: step });
              }
            }
            break;
          }
        }
        while (true) {
          if (step < max) {
            step += 100000;
            ranges.push({ min: step - 100000, max: step });
          } else {
            break;
          }
        }
    
        return ranges;
      };
      const GetList = (
        make,
        model,
        priceRange,
        province,
        year,
        transmission,
        newUsed,
        body
      ) => {
        return vehicles
          .filter((v) => {
            return make ? v.brand.toUpperCase() === make.toUpperCase() : v;
          })
          .filter((v) => {
            return model ? v.model.toUpperCase() === model.toUpperCase() : v;
          })
          .filter((v) => {
            return priceRange
              ? v.price >= priceRange.min && v.price < priceRange.max
              : v;
          })
          .filter((v) => {
            return province
              ? v.province.toUpperCase() === province.toUpperCase()
              : v;
          })
          .filter((v) => {
            return year ? v.year === year : v;
          })
          .filter((v) => {
            return transmission
              ? v.transmission.toUpperCase() === transmission.toUpperCase()
              : v;
          })
          .filter((v) => {
            return newUsed ? v.newUsed.toUpperCase() === newUsed.toUpperCase() : v;
          })
          .filter((v) => {
            return body ? v.bodyType.toUpperCase() === body.toUpperCase() : v;
          });
      };
      useEffect(() => {
        const filteredData = vehicles
          .filter((v) => {
            return activeMake
              ? v.brand.toUpperCase() === activeMake.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeModel
              ? v.model.toUpperCase() === activeModel.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activePriceRange
              ? v.price >= activePriceRange.min && v.price <= activePriceRange.max
              : v;
          })
          .filter((v) => {
            return activeProvince
              ? v.province.toUpperCase() === activeProvince.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeYear ? v.year === activeYear : v;
          })
          .filter((v) => {
            return activeTransmission
              ? v.transmission.toUpperCase() === activeTransmission.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeNewUsed
              ? v.newUsed.toUpperCase() === activeNewUsed.toUpperCase()
              : v;
          })
          .filter((v) => {
            return activeActiveBodyType
              ? v.bodyType.toUpperCase() === activeActiveBodyType.toUpperCase()
              : v;
          });
        if (filteredData?.length > 0) {
          const prices = [...new Set(filteredData.map((m) => m.price))];
          setPriceRanges(CalcPriceRanges(prices));
        }
      }, [
        activeMake,
        activeModel,
        activeProvince,
        activePriceRange,
        activeYear,
        activeTransmission,
        activeNewUsed,
        activeActiveBodyType,
        vehicles,
      ]);
 
    const calculateMonthly = (data) => {
        var deposit = data.purchasePrice * (data.deposit / 100);
        var totalPrice = data.purchasePrice;
        var initFee = 0;
        var principal = totalPrice - deposit + initFee;

        var balloonPerc = data.balloonPayment;

        var balloonAmt = totalPrice * balloonPerc / 100;

        var interestRate = data.linkedInterestRate;
        var interestPM = interestRate / 100 / 12;

        var repaymentPeriod = data.term;
        var days = 1;

        var v = 1 / (1 + interestPM);
        var d = 1 - v;
        var y = Math.pow(v, repaymentPeriod - 1);
        var comp = (1 - y) / d;
        var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
        var monthly = 0;

        if (parseInt(balloonAmt) > 0) {
            var comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
            monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
        }
        else {
            var comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
            monthly = fp / comp;
        }

        return round(monthly) + initFee;
    }

    function round(x) {
        return Math.round(x * 100) / 100;
    }

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();

        axios.get(`${BASE_URI}/stock/${activeVehicle?.stockId}`, {
            cancelToken: source.token
        }).then(result => {

            let data = result.data
            setModelData(data);
            setGlobalModel(data);

            var tmpFinanceDetails = {
                monthlyInstallment: 0,
                term: 72,
                linkedInterestRate: 7,
                deposit: 0,
                balloonPayment: 0,
                originalPrice: data.price,
                purchasePrice: data.price
            };

            let monthly = calculateMonthly(tmpFinanceDetails);

            tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

            setFinanceDetails(tmpFinanceDetails);
            setGlobalFinance(tmpFinanceDetails);

            setImages(data.mainUrls.map(itm => { return { img: itm }; }));

            setLoading(false)
        });

    }, [activeVehicle]);

    const padZero = (str, len) => {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    const stepsState = {
        one: {
            active: true,
            completed: false
        },
        two: {
            active: true,
            completed: false
        },
        three: {
            active: true,
            completed: false
        }
    }

    const getMainImage = () => {

        if (images.length > 0)
            return images[0].img ? images[0].img : ComingSoon;
        else
            return ComingSoon;
    }

    return (
        <React.Fragment>
            <main>
                <Container maxWidth="lg">
                    {
                        compLoading &&
                        <Grid item container justifyContent='center' justify='center' alignContent='center' xs={12}>
                            <CircularProgress size={80} />
                        </Grid>
                    }
                    {
                        !compLoading &&
                        <div className={classes.background}>
                         
                            <Grid container md={12} className={classes.flex}>
                            <Grid md={2} xs={12}>
              {(loading ||searchLoading) && (
                <div className={classes.filterLoadingContainer} />
              )}
              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="All Makes"
                onChange={(e) => setActiveMake(e.target.value)}
              >
                {makes.map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Any model"
                onChange={(e) => setActiveModel(e.target.value)}
                onCompositionStart={() => setActiveModel(null)}
              >
                {Array.from(
                  new Set(GetList(activeMake).map((v) => v.model))
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="All prices ranges"
                onChange={(e) => setActivePriceRange(e.target.value)}
              >
                {priceRages.map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {`R${option?.min} - R${option?.max - 1}`}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="All Provinces"
                onChange={(e) => setActiveProvince(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(activeMake, activeModel, activePriceRange).map(
                      (v) => v.province
                    )
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Any year"
                onChange={(e) => setActiveYear(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince
                    ).map((v) => v.year)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Man / Auto"
                onChange={(e) => setActiveTransmission(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince,
                      activeYear
                    ).map((v) => v.transmission)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Used / Demo"
                onChange={(e) => setActiveNewUsed(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince,
                      activeYear,
                      activeTransmission
                    ).map((v) => v.newUsed)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                id="dealerId"
                select
                label="Body type"
                onChange={(e) => setActiveBodyType(e.target.value)}
              >
                {Array.from(
                  new Set(
                    GetList(
                      activeMake,
                      activeModel,
                      activePriceRange,
                      activeProvince,
                      activeYear,
                      activeTransmission,
                      activeNewUsed
                    ).map((v) => v.bodyType)
                  )
                ).map((option, i) => (
                  <MenuItem key={i} id={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

             
              <Box mb={3} className={classes.btn2}>
                <Button
                  disabled={loading}
                  gutterBottom
                  variant="contained"
                  color="primary"
                  className={classes.btn2}
                  onClick={()=>onFilterData(activeMake,activeModel,activePriceRange,activeProvince,activeYear,activeTransmission,activeNewUsed,activeActiveBodyType)}
                >
                  {!searchLoading
                    ? showFilteredData
                      ? "RESET"
                      : "SEARCH"
                    : ""}
                  {searchLoading && <CircularProgress />}
                </Button>
              </Box>
            </Grid>
                            <Grid container spacing={2} md={10} style={{ marginBottom: '1rem' }}>
                                <Grid item xs={12} md={7}>
                                    <Box >
                                        <ImageContainer aspectRatio={(16 / 6)} src={getMainImage()} alt='MainImage' />
                                        </Box>
                                        {images.length > 1 &&
                                            <Box style={{ marginBottom: '1rem' }}>
                                                {

                                                    <Carousel naturalSlideWidth={100}
                                                        naturalSlideHeight={60}
                                                        totalSlides={images.length}
                                                        visibleSlides={4}
                                                        isPlaying={true}
                                                        infinite
                                                        innerClass={classes.slideMargin}
                                                        slides={images}
                                                    />
                                                }
                                            </Box>
                                        }
                                    <Box className={classes.specs} mt={5}>
                                    <Typography variant="h4" align="left" > Factory Features on this Vehicle</Typography>
                                       

                                       ABS, Electronic brakeforce distribution, Air bags 4, Day lights, Auto lock, Power steering, Steering adjustment, Cruise control, Onboard computer, Seats 5, Spare wheel FULLSIZE, Keyless entry, Air conditioner, Electric windows, Radio, Remote side mirrors, Satelite navigation, Alloy wheels, Fuel UNLEADED, Engine TRANSVERSE INLINE 3, Engine size 898cc, CO2 126g/Km, Power 66kW@5250, Torque 140Nm@2500, 4 VALVES PER CYLINDER, VVT, Gears 5, Drive 2, 0-100Km/h 12.9sec, Top speed 178Km/h, Boot capacity 300 litres, Average fuel consumption 5.5l/km, Service interval 15000km
                                    </Box>
                                  
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <Formik
                                        initialValues={values}
                                        isInitialValid={false}
                                        onSubmit={(values, actions) => {
                                            setTimeout(() => {

                                                actions.setSubmitting(true);
                                            }, 100);
                                        }}
                                    >
                                        {(props) => {
                                            const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

                                            return (
                                                <Form>
                                                   <Box ml={1}>
                                                   <Box mb={1} mt={1}>
                                                   <Typography className={classes.smallHeading}>
                                                        {modelData.model}
                                                    </Typography>
                                                    </Box>
                                                    <div className={classes.headerLine}></div>
                                                    <Box mb={1} mt={1}>
                                                    <Typography variant="h4" color="textPrimary">
                                                        <strong>
                                                            <CurrencyValue value={financeDetails.purchasePrice} />
                                                        </strong>
                                                    </Typography>
                                                
                                                    <Typography variant="p" color="textPrimary">
                                                            
                                                        <span className={classes.lighter}>
                                                        From <CurrencyValue value={financeDetails.monthlyInstallment}/> pm
                                                        </span>
                                                    </Typography>
                                                    </Box>
                                                   </Box>
                                                   
                                                    <PaymentDetails displayPrice={false} details={financeDetails ? financeDetails : carProfile.dealDetails} />
                                                
                                                    <Box mb={3} mt={3}  className={`${classes.stepOneButtons} ${classes.flex}`} style={{ width: 'initial', display: 'flex' }}>
                                                    
                                                            <Button variant="contained" gutterBottom onClick={() => { history.push(`/contact-us`) }} color="primary"  className={classes.btn}>
                                                                Request Call back
                                                            </Button>

                                                            <Button variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`) }}  className={classes.btn1}>
                                                                Pre-Qualify Now
                                                            </Button>
                                                     
                                                      
                                                          
                                                      
                                                    </Box>

                                                    
                                                        <Box style={{ marginTop: 24 }}>
                                                        <table border="0" width="100%" cellspacing="0px" cellpadding="15px">
                                                                <tbody>
                                                             
                                                                    <tr >
                                                                        <td className={classes.darkgreyleft}>
                                                                         Year
                                                                        </td>
                                                                        <td className={classes.darkgreyright}>
                                                                        {modelData.year}
                                                                        </td>
                                                                     </tr>
                                                                     <tr >
                                                                         <td className={classes.darkgreyleft}>
                                                                             Mileage
                                                                         </td>
                                                                         <td className={classes.darkgreyright}>
                                                                        {modelData.mileage} km
                                                                         </td>
                                                                      </tr>
                                                                      <tr>
                                                                         <td className={classes.darkgreyleft}>
                                                                             Colour
                                                                         </td>
                                                                         <td className={classes.darkgreyright}> 
                                                                        {modelData.colour}
                                                                         </td>
                                                                      </tr>
                                                                      <tr >
                                                                         <td className={classes.darkgreyleft}>
                                                                             Body
                                                                         </td>
                                                                         <td className={classes.darkgreyright}>
                                                                        {modelData.bodyType}
                                                                         </td>
                                                                      </tr>
                                                                      <tr >
                                                                         <td className={classes.darkgreyleft}>
                                                                             Transmission
                                                                         </td>
                                                                         <td className={classes.darkgreyright}>
                                                                        {modelData.transmission == "M" ? "Manual" : "Auto"}
                                                                         </td>
                                                                      </tr>
                                                                      <tr >
                                                                         <td className={classes.darkgreyleft}>
                                                                             Fuel
                                                                         </td>
                                                                         <td className={classes.darkgreyright}>
                                                                        {modelData.fuelType == "D" ? "Diesel" : "Unleaded"}
                                                                         </td>
                                                                      </tr>
                                                                </tbody>
                                                            </table>
                                                        </Box>
                                                    <Box>
                                                        <Typography style={{ marginTop: '.5rem' }} variant="caption" color="textSecondary">
                                                            {financeDetails.termsAndConditions}
                                                        </Typography>
                                                    </Box>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </Grid>
                            </Grid>
                            </Grid>
                        </div>
                    }
                </Container>
            </main>
        </React.Fragment>
    );
}